import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000",
    "col2n": "#E4B670"
}
function FacMou102({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M59.55 9.45q10.25-1.6 10.25-3.9T59.55 1.6Q49.35 0 34.9 0T10.2 1.6Q0 3.25 0 5.55q0 2.3 10.2 3.9 10.25 1.65 24.7 1.65t24.65-1.65z"
                    id="FacMou102__Symbol_186_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M81.85 28.85l.15-.25q.05-.1.1-.15.25-.4.45-.85.65-1.5.65-3.25 0-.95-.55-2.7-.2-.65-.5-1.4-1.85-3.6-9.75-5.2-3.75-.7-4.95-1.25-1.45-.8-3.3-1.7-1.75-.9-3.25-1.75-1.5-.9-4.95-2.95-3.45-2.05-5.5-2.25-.15-.05-.3-.05-1.05-3.25-4-4.4-2.1-.7-4.2-.7h-.7q-2.1 0-4.2.7-2.95 1.15-4 4.4-.15 0-.3.05-2.05.2-5.5 2.25t-4.95 2.95q-1.5.85-3.25 1.75-1.85.9-3.3 1.7-1.2.55-4.95 1.25-7.9 1.6-9.75 5.2-.3.75-.5 1.4Q0 23.4 0 24.35q0 1.75.65 3.25.2.45.45.85.05.05.1.15l.15.25q2.35 3.3 6 5.45 4.7 2.45 11.4 3.4l.5.1q.25 0 .55.05l.15.05q5.8.55 9.05 1.8 2.6 1 4.6 1.75 7.9 3.35 16 0 2-.75 4.6-1.75 3.25-1.25 9.05-1.8l.15-.05q.3-.05.55-.05l.5-.1q6.7-.95 11.4-3.4 3.65-2.15 6-5.45M74.7 23.6l-.25.45q-.3.45-1 .95-.8.35-2.05.75-.75.25-1.65.45-5.8 1.4-11.6 0-1.1-.2-2.25-.5-1-.25-2-.55-5.35-1.55-11.4-1.75h-1.8q-6.05.2-11.4 1.75-1 .3-2 .55-1.15.3-2.25.5-5.8 1.4-11.6 0-.9-.2-1.65-.45-1.25-.4-2.05-.75-.7-.5-1-.95l-.25-.45v-.15q0-.25.15-.45.4.25 1 .5l.75.25q8.35 2.95 18.45.05.2-.1.45-.15 5.75-1.7 11.7-1.8H42.2q5.95.1 11.7 1.8.25.05.45.15 10.1 2.9 18.45-.05l.75-.25q.6-.25 1-.5.15.2.15.45v.15z"
                    id="FacMou102__Symbol_185_0_Layer0_0_FILL"
                />
                <path
                    d="M189.25 196q-.6 0-1.05.65-.45.65-.45 1.45 0 .9.45 1.5.45.65 1.05.65.6 0 1.05-.65.45-.6.45-1.5 0-.8-.45-1.45-.45-.65-1.05-.65m7.55.65q-.45-.65-1.05-.65-.6 0-1.05.65-.45.65-.45 1.45 0 .9.45 1.5.45.65 1.05.65.6 0 1.05-.65.45-.6.45-1.5 0-.8-.45-1.45z"
                    id="FacMou102__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacMou102__Symbol_186_0_Layer0_0_FILL"
                transform="translate(158 212)"
                id="FacMou102__col1n"
            />
            <use
                xlinkHref="#FacMou102__Symbol_185_0_Layer0_0_FILL"
                transform="translate(150.9 193.35)"
                id="FacMou102__col2n"
            />
            <use xlinkHref="#FacMou102__Layer1_0_FILL" />
        </g></g>
    )
}
export default FacMou102
