import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FF98D0",
    "col1l": "#FFECF6",
    "col1n": "#FFCDE8",
    "col2n": "#FFD0A3"
}
function ObjFoo063({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#FFCDE8"
                    d="M265.55 186.45h.15q-.117-.814-.2-1.7v.1q.089.829.05 1.6z"
                    id="ObjFoo063__Layer8_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M52.05 16.4q-.3-.35-.6-.65-.7-.65-2.15-1.5-2.25-1.3-6.05-3.05-4.65-2.05-6.6-5.4-1.3-2.2-1.85-5.8h-.15q-.1 1.85-.9 3.35-1.1 2-3.15 2.95-2.85 1.25-4.9 3.25-1.6 1.6-2.4 3.4-1.5 3.25-1.9 6.05h-.35q-2.1 0-3.95.55h-.05q-3.15.95-5.65 3.4l-.05.05q-.95 1.05-1.75 2.2-1.25 1.9-1.75 4.1-.25 1.15-.3 2.4-.05.45-.05.8v.1q0 .95.15 1.9 0 .4.1.85 0 .05.05.1 0 .1.05.25v.1q.3 1.35.95 2.6.05.05.05.1-.1.05-.25.1-.05.05-.1.05-.1 0-.1.05-.3.1-.55.25-.4.2-.8.5-1.35.8-2.6 1.95-.1.15-.15.2Q0 45.9 0 52q0 .45.05.85 0 1.1.25 2.1.15.8.4 1.55.6 2.05 1.85 3.85.75 1.05 1.75 2.05l.15.15q.95.9 1.95 1.6.3.25.7.45v.1q0 1.6.3 3.15.15.95.45 1.85.25.75.65 1.5 1.05 2.25 3 4.2.4.4.8.75 4.25 3.8 10 3.8 3.1 0 5.75-1 .25-.05.5-.2.55-.15 1.3-.25h3.3q.05 0 .2.1.35.1 1.1.4.25.1.55.2.9.3 2.3.8 1.5.6 3.05.9 1 .3 2.1.45 1.15.15 2.35.15 5.65 0 9.8-3.6.5-.45 1-.95 2.55-2.55 3.65-5.75 0-.05.05-.05.45-1.6.7-3.35h.1q3.45-1.05 6.2-3.8 3.25-3.25 4.05-7.5.25-1.4.25-2.95 0-.7-.05-1.45-.3-3.4-2.05-6.25-.35-.55-.75-1.1-.05-.05-.15-.1-.05-.15-.1-.2-.1-.15-.2-.25.05-.1.15-.15.05-.15.15-.3.3-.4.5-.85 0-.1.05-.15.05-.15.15-.25 1.2-2.7 1.2-5.9 0-5.75-4.1-9.85-2.45-2.4-5.4-3.4-.65-.15-1.2-.3-.1-.05-.2-.05-1.45-.35-3.05-.35-.6 0-1.15.05h-.1v-.45q-.35-3.6-2.25-5.85M41.7 37.05q-.3-.05-.65-.05h-.2q.1-.05.25-.05.5-.05.6.1z"
                    id="ObjFoo063__Symbol_146_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M43.55 2.15q-.3-.35-.6-.65-.7-.65-2.15-1.5 3.5 4.6 1.55 8.1-.1.15-.15.25-.1.2-.25.4l-.05.05q-.8.85-2.4 1.35-2.4.8-4 1.3 1.6-.05 3.45 0 13.5-.2 16.2 8.35.15.5.15 1l.1.85q0 1.7-.8 3.4l-.35.5q-.15.3-.3.5l-.3.5q-.7.9-1.65 1.75-3.85 3.65-9.25 4H37.5q.25.15.45.25 1.4.75 3.2 1.4 2.15.45 4.15.7h1.8l.7-.1h.85q1.35-.2 3.3-.5 1.55-.25 2.95-.85.7-.3 1.4-.75.65.1 1.35 2.3.6 2.1.6 3.5 0 1.35-.25 2.45-.25.9-.65 1.55 0 .05-.05.1-.55 1.1-1.35 2.05-.95 1.1-2.25 2-7.85 5.6-22.85 2.05-2.25-.35-3.35-.75v.15q2 2.65 7.35 4.55 4.35 1.5 7.65 1.65.15 0 .35.05h1.4v.1q0 2.2-1.7 3.9-2.2 2.15-6.2 2.15h-1.7q-.2-.05-.35-.1-2-.3-4.2-.9-2.8-.75-4.6-1.1H23q-.1 0-.15.05-1.8.45-4.25 1.5-1.95.85-3.45 1.25-2.15.7-4.55.7-4.8 0-8-2.2Q1 58.4 0 56.95q1.05 2.25 3 4.2.4.4.8.75 4.25 3.8 10 3.8 3.1 0 5.75-1 .25-.05.5-.2.55-.15 1.3-.25h3.3q.05 0 .2.1.35.1 1.1.4.25.1.55.2.9.3 2.3.8 1.5.6 3.05.9 1 .3 2.1.45 1.15.15 2.35.15 5.65 0 9.8-3.6.5-.45 1-.95 2.55-2.55 3.65-5.75 0-.05.05-.05.45-1.6.7-3.35h.1q3.45-1.05 6.2-3.8 3.25-3.25 4.05-7.5.25-1.4.25-2.95 0-.7-.05-1.45-.3-3.4-2.05-6.25-.35-.55-.75-1.1-.05-.05-.15-.1-.05-.15-.1-.2-.1-.15-.2-.25.05-.1.15-.15.05-.15.15-.3.3-.4.5-.85 0-.1.05-.15.05-.15.15-.25 1.2-2.7 1.2-5.9 0-5.75-4.1-9.85-2.45-2.4-5.4-3.4-.65-.15-1.2-.3-.1-.05-.2-.05-1.45-.35-3.05-.35-.6 0-1.15.05h-.1V8q-.35-3.6-2.25-5.85z"
                    id="ObjFoo063__Symbol_145_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M5.4 36.9q-1.45-1.25-2.55-2.75-.1-.4-.15-.75l-.2.2q-1.2 1.1-1.9 3-.6 1.65-.6 3.1 0 1.8 1.85 3.7 1.8 1.75 4.2 2.5.1.05.2.05 3.7 1 6.95 1.55 5.4.95 9.35.7 3.55-.3 6.1-.7 1.85-.3 3.15-.7 2.95-.95 3.45-1.15.45-.2.7-.5-4.75.75-9.35.15-14.45-2.2-21.2-8.4m1.95-18.4q.1 2.25 2.95 4.45 6.75 3.7 15 4.7 4.05.5 8.35.4-.3-.05-.65-.05h-.2q.1-.05.25-.05.5-.05.6.1.75 0 1.55-.05 1.7-.1 3.2-.35 2.65-.4 2.9-.4-4.75-.05-9.6-1.2Q15.15 22.95 13.4 14l-.1-.7q-1.65.2-2.85.7-1.85.75-2.65 2.2-.55 1.15-.45 2.3M23.25.25V0q-1.3 1.4-1.7 1.85-.7 1.15-.7 2.8 0 2.35 2.8 3.6t4.45.85h-.2q.151-.004.2-.2v.2q1.6 0 2.8-.4-1.2-.35-2.15-.75-2.15-.9-3.3-2.1-1.75-1.65-2.1-4.15-.1-.75-.1-1.45z"
                    id="ObjFoo063__Symbol_144_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M1.65 0H0v25.6q.15.1 1.2 1.6 1 1.45 1.3 2.25.25.8.45 1.35V.5Q2.2.2 1.85.1 1.7 0 1.65 0z"
                    id="ObjFoo063__Symbol_143_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjFoo063__Layer8_0_FILL" />
            <use
                xlinkHref="#ObjFoo063__Symbol_146_0_Layer0_0_FILL"
                transform="translate(226.45 181.4)"
                id="ObjFoo063__col1n"
            />
            <use
                xlinkHref="#ObjFoo063__Symbol_145_0_Layer0_0_FILL"
                transform="translate(234.95 195.65)"
                id="ObjFoo063__col1d"
            />
            <use
                xlinkHref="#ObjFoo063__Symbol_144_0_Layer0_0_FILL"
                transform="translate(234.5 190.4)"
                id="ObjFoo063__col1l"
            />
            <use
                xlinkHref="#ObjFoo063__Symbol_143_0_Layer0_0_FILL"
                transform="translate(257.95 259.9)"
                id="ObjFoo063__col2n"
            />
        </g></g>
    )
}
export default ObjFoo063
