import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}
function SkiMak025({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M104.55 11.7q-.75-.9-1.6-1.8-1.9-2-3.85-3.6Q92.1.7 83.55.1 72.55-.75 65.3 7q-4.7 5.05-6.5 13.35Q57 12.05 52.3 7 45.05-.75 34.05.1q-6.85.5-12.7 4.2-.3 0-5.35 4.4-5.1 4.4-6.4 5.8-1.3 1.35-1.95 1.95-.7.55-2.2 2.45-1.55 1.85-1.75 2.35-.2.45-.2.6 0 .55.15 1.35.15.75.15 1.5v.8q0 2.65-2.5 5Q0 32.1 0 32.6q0 .85 1.9 2.35Q3.2 36 3.6 36.9q0 .05.05.1l.3.5.15.65v2.75q-.35 2.2-.35 3.75 0 1.75 1.35 3.5 1.35 1.8 3.85 3.2.9.5 1.7.95 1.95 1.6 4.5 4.5.5.7 1.3 1.85 1.7 2.45 3.75 2.95h2.3l2.9-.45h1q2.1.4 3.35 1.9 1.65 1.9 2.9 2.3h1.4Q35 65.2 36.9 64q.05-.05.1-.05.3-.05.6-.05 1.8 0 2.25 2.2.2.65.35 1.6v5.15q.45.8 1.5.8 1.4 0 1.85-2.65.25-3 .35-3.6.05-.45.05-1.65-.05-.9.05-1.4v-.15l.05-3.25q0-1.25.25-1.65.1-.2.25-.3.05-.1.15-.1h1.35q.4.25.5.75.05.25.15.8.15.65.35.85.3.3.95.45.05.05.1.05h.15q.35.05.8.05 1.35 0 1.75-1.4.25-1.8.45-2.6T53 53.6q.05-.15.15-.35.2-.25.35-.5.35-.4.6-.7.6-.8 1.3-2 .75-1.4 1.1-2.05.9-1.6 1.55-4.1.65-2.55.65-4.4v-.9q.05.4.15.9.05 3.25 1.05 6.55.95 3.05 2.5 4.55.4.5.8.7.55.5 1.35.8 1.3.5 1.95 1.4.35.55.7 1.45.1.2 1.15 2.85 1.6 2.55 4.55 2.55h.35q3.65.5 4.4 4.15.25 1.05.35 6.9.3 5.35 1.7 5.35 1.3 0 1.9-3.85.4-4 .4-4.3.3-3.2.65-4.35.05-.15.1-.25v-.05q0-.8 1.4-1.75 1.55-1.1 3.3-1.1.7 0 1.95.45.95.35 1.2.5.2.1.6.25.25.15.55.35.7.4 1.95.65h1.55q.35-.15.75-.35 2.1-.6 3.6-1.9 1.25-1.05 3-3.25.35-.35 1.2-1.6.85-1.1 1.5-1.6.2-.15.95-.65.5-.2.95-.35 1.4-.45 2.15-1.4.45-.55.95-1.75.1-.3.65-2.15l.2-.5q.85-2.1 1.7-5.1.5-2.05.65-2.55.5-1.45 1.4-2.4.9-1 2.7-2.4.15-.1 1.25-.65.25-.1.55-.2.05-.05.15-.1.1-.05.25-.1 1.6-.4 1.6-1.2 0-.5-1.55-1.5-.75-.25-1.75-.7-1.3-.6-2.1-1.05-1.2-.75-1.65-1.45-.35-.6-.35-1.5.25-1.3.25-2.4-.1-.75-.15-1.45-.15-.45-.25-.65-.4-1.05-1.05-1.75-1.05-.85-1.95-1.75-.9-.9-1.7-1.95-.85-1.1-2.25-2.75-1.25-1.35-2.35-2.5z"
                    id="SkiMak025__Symbol_32_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#SkiMak025__Symbol_32_0_Layer0_0_FILL"
                transform="translate(133.7 136.35)"
                id="SkiMak025__col1n"
            />
        </g></g>
    )
}
export default SkiMak025
