import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#4D2E1B",
    "col1n": "#794D32",
    "col2l": "#BFBFBF",
    "col2n": "#999999"
}
function ObjToo078({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M21.5 4.5l-.05-.05Q21.2 4.3 21 4.2l-.05-.05q-1.85-1.5-2.75-2.2V.7q-.15.55-.25.95-.85-.55-3.7-1.35Q14 .15 13.8.05L13.7 0q-.3.45-.75.8-.05.1-.15.2-1.45 1.75-2.95 4-.1.15-.2.25Q8.45 7 7.2 9.15q.4 1.15.4 2.5 0 3.15-2.3 5.4-1.4 1.45-3.25 2Q.95 21.55 0 24.2q.5 2.35 1.7 2.9 1.2.55 2 1.25v-.1q.25.15.9.2 1.45.05 2.85.05.8-.95 1.6-1.95.1-.1.15-.2-2-3.15-2-7.35 0-4.85 2.65-8.3 2.05-2.65 4.75-3.25l.5-.15q2.75-.7 5.6-.45l.05-.05q.15-.5.35-1 .05-.15.1-.25.15-.5.3-1.05z"
                    id="ObjToo078__Symbol_251_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M8.05 4.2L8 4.15q-1.15-.6-2.4-1.3-.05-.05-.35-.2l-.4-.25q-.05-.05-.1-.05L1.3.3Q1.05.15.85.05L.75 0Q.45.45 0 .8l.8.75q1.3 1.1 2.65 1.95.05.05.15.1.3.2.6.35 1.45.75 3 1.3l1.05.3q.15-.5.3-1.05l-.05-.05q-.25-.15-.45-.25z"
                    id="ObjToo078__Symbol_247_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M2.75 82.7Q1.3 82.85.5 84.15q-1.6 2.8 1.95 5.2l.7.5q.05.05.1.05.15.1.4.25l.1.05q.05.05.1.05.05.05.1.05.35.15.65.3 3.85 1.85 5.45-.95.7-1.35.15-2.65-4.796-.593-7.45-4.3M43.5 28.6q2-4 3.85-8Q51.9 10.45 55.15 0q-8.1 9.2-13.95 17.05-1.95 2.7-3.7 5.3-4.6 6.75-10.3 17-3.6 6.4-7.6 14.15L9.1 47.45l-2.2 3.8 9 6.9.05-.05q.2.2.45.35l.05.05.1.05q.07.06 1.7 1.05 1.63.985 3.6 2.1 1.969 1.17 2.15 1.1.09.084.2.15l.05.05h.05q.25.15.6.25l-.05.05 10.45 4.35 2.2-3.8-10.85-6.3.1-.2q5.8-8.75 11-18 2.95-5.3 5.75-10.75z"
                    id="ObjToo078__Symbol_250_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M3.2 85.95l.05.1q-.45-.2-.75-.45L0 89.9q.15.1.4.25l.1.05q.05.05.1.05.05.05.1.05.35.15.65.3 3.85 1.85 5.45-.95.7-1.35.15-2.65-1.35-.05-2.6-.5-.6-.2-1.15-.55M14.5 53.9l1.25.7.6-1.1-10.5-6.05 8.65 6.45m29.6-33.3Q48.65 10.45 51.9 0L19.95 55.3l-.1.2q-.35.65-.55 1.15l4.65 2.7 10.3 4.5-10.85-6.3.1-.2q5.8-8.75 11-18 2.95-5.3 5.75-10.75 2-4 3.85-8z"
                    id="ObjToo078__Symbol_249_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjToo078__Symbol_251_0_Layer0_0_FILL"
                transform="matrix(-1 0 0 1 131.9 279.95)"
                id="ObjToo078__col1n"
            />
            <use
                xlinkHref="#ObjToo078__Symbol_247_0_Layer0_0_FILL"
                transform="matrix(-1 0 0 1 118.95 279.95)"
                id="ObjToo078__col1d"
            />
            <use
                xlinkHref="#ObjToo078__Symbol_250_0_Layer0_0_FILL"
                transform="matrix(-1 0 0 1 134.65 221.45)"
                id="ObjToo078__col2n"
            />
            <use
                xlinkHref="#ObjToo078__Symbol_249_0_Layer0_0_FILL"
                transform="matrix(-1 0 0 1 131.4 221.45)"
                id="ObjToo078__col2l"
            />
        </g></g>
    )
}
export default ObjToo078
