import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}
function FacEar018({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M110.05 33.25q3.2-4.2 6.9-8.3-1.25-1.1-3-2.45Q95.9 9.95 77.8 4.4q-.95-.3-1.9-.55Q61.3-.35 46.65.05q-1.55 0-3.05.1L35.8.9q-1.35.2-2.6.45l-1.65.25q-.35.05-.7.15l-2.95.7q-.35.05-.7.15-4.35 1.1-8.4 2.5-.85.3-1.65.6-3.65 1.45-7 3.35-4.6 2.5-8.6 5.8-3.05 3.5 0 5 1.3-.1 3.25-.75 1.4-1 3.05-1.8.35-.2.65-.3 1.2-.65 2.5-1.2l97.85 16.6q.6.4 1.2.85m260.3-13.35q3.05-1.5 0-5-4-3.3-8.6-5.8-3.35-1.9-7-3.35-.8-.3-1.65-.6-4.05-1.4-8.4-2.5-.35-.1-.7-.15l-2.95-.7q-.35-.1-.7-.15l-1.65-.25q-1.25-.25-2.6-.45L328.3.2q-1.5-.1-3.05-.1Q310.6-.3 296 3.9q-.95.25-1.9.55Q276 10 257.95 22.55q-1.75 1.35-3 2.45 3.7 4.1 6.9 8.3l.5-.3 96.05-18.2q2.7 1 5 2.25.3.1.65.3 1.65.8 3.05 1.8 1.95.65 3.25.75z"
                    id="FacEar018__Symbol_49_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M101.55 24.3q-7.25-5-13.05-8.55Q74.1 7.1 59.9 3.45q-24.3-6.9-48 0-3.15.95-6.2 2.1-3.1 1.1-5.7 2.5l1.5.6q6 3.65 10.1 6.85 1.4 1.1 2.6 2.15l.1.1q.05.1.15.15h.05q.2 0 .45-.05-.2.1-.35.15.45.45.9.85 2.5 2.5 3.65 4.7l.4.6q.8 1.2 1.5 2.35 2.05 3.4 3.2 6.5.8 2.2 1.25 4.3.05.35.75 5.1.05.4.1 1 .1.95.2 2.4.05 1 .1 2.2v2.8q.1 4.6.2 11.05.4 3.95.85 8.2 1.15 6.65 5.15 11.75.7.9 3.6 6 1.3 2.35 1.65 5.9.15 1.9.15 4.3.35 4.5 1.95 8.2 1.15 2.8 3.35 5.6.15.2.35.35 4.2 4.15 8.95 6.85 14.15 7.95 33.25 3.05 3.05-.3 3.4-1-.55-1.5-1-2.95l-3.35-12Q81.7 92 82 78.75q.65-29.45 19.55-54.45M354.9 8.1q-2.6-1.4-5.7-2.5-3.05-1.15-6.2-2.1-23.7-6.9-48 0-14.2 3.65-28.6 12.3-5.8 3.55-13.05 8.55 18.9 25 19.55 54.45.3 13.25-3.15 27.35l-3.35 12q-.45 1.45-1 2.95.35.7 3.4 1 19.1 4.9 33.25-3.05 4.75-2.7 8.95-6.85.2-.15.35-.35 2.2-2.8 3.35-5.6 1.6-3.7 1.95-8.2 0-2.4.15-4.3.35-3.55 1.65-5.9 2.9-5.1 3.6-6 4-5.1 5.15-11.75.45-4.25.85-8.2.1-6.45.2-11.05v-2.8q.05-1.2.1-2.2.1-1.45.2-2.4.05-.6.1-1 .7-4.75.75-5.1.45-2.1 1.25-4.3 1.15-3.1 3.2-6.5.7-1.15 1.5-2.35l.4-.6q1.15-2.2 3.65-4.7.45-.4.9-.85-.15-.05-.35-.15.25.05.45.05h.05q.1-.05.15-.15l.1-.1q1.2-1.05 2.6-2.15 4.1-3.2 10.1-6.85l1.5-.6z"
                    id="FacEar018__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M101.55 24.3q-7.25-5-13.05-8.55Q74.1 7.1 59.9 3.45q-24.3-6.9-48 0-3.15.95-6.2 2.1-3.1 1.1-5.7 2.5l1.5.6q6 3.65 10.1 6.85 21.45-7.35 40.85-1.45l1 .2q8.1 3.15 13.75 7.95Q78.3 31.55 79.65 47q.2 2.2.2 4.5v17.25q.5 6.8 2.15 10 .65-29.45 19.55-54.45m241.75-8.75q4.1-3.2 10.1-6.85l1.5-.6q-2.6-1.4-5.7-2.5-3.05-1.15-6.2-2.1-23.7-6.9-48 0-14.2 3.65-28.6 12.3-5.8 3.55-13.05 8.55 18.9 25 19.55 54.45 1.65-3.2 2.15-10V51.55q0-2.3.2-4.5 1.35-15.45 12.45-24.8 5.65-4.8 13.75-7.95l1-.2q19.4-5.9 40.85 1.45z"
                    id="FacEar018__Symbol_50_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar018__Symbol_49_0_Layer0_0_FILL"
                transform="translate(6.6 62.45)"
                id="FacEar018__col1n"
            />
            <use
                xlinkHref="#FacEar018__Symbol_51_0_Layer0_0_FILL"
                transform="translate(15.1 71.4)"
                id="FacEar018__col2n"
            />
            <use
                xlinkHref="#FacEar018__Symbol_50_0_Layer0_0_FILL"
                transform="translate(15.1 71.4)"
                id="FacEar018__col2d"
            />
        </g></g>
    )
}
export default FacEar018
