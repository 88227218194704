import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#121212",
    "col1l": "#434343",
    "col1n": "#252525",
    "col2n": "#434343"
}
function AccHat167({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M224.05 148.15q5.15-.75 10.1-1.65 16.05-2.9 30.3-7.4l.4-.1.6-.2 5.8-1.85q10.3-3.95 14.05-10.6.1-.3.25-.6.95-2.65.95-5.95 0-.9-.4-4.15-.1-1.85-.65-3.5-.15-.5-.35-1-.2-.5-.4-.95-7.05-13-58.55-4.2-7.8 1.55-12.8 1.95-55.35 9.55-79.7 14.9-19.6-5.5-65.85-15.55-5.35-.8-12.7-2.4-47.75-7-54.05 7.25-.55 1.65-.65 3.5-.4 3.25-.4 4.15 0 3.3.95 5.95.15.3.25.6 3.75 6.65 14.05 10.6l5.8 1.85.6.2.4.1q14.25 4.5 30.3 7.4 4.95.9 10.1 1.65 7 1 14.35 1.7 69 5.55 135.35-.25 6.05-.6 11.9-1.45m-145.8-24.3v.2h-.2l.2-.2M167.8 35.1q-1.6-7.65-4-11.45l-.5-.5q-1 .65-2.05 1.3-.25.15-.45.25-.2-.85-.45-1.65-.9-3.1-2.25-5.4-.35-.5-.7-.95-.95-1.15-1.7-2.15-1-1.05-2.7-2.85-1.35-1.3-2.55-2.45-.8-.75-1.5-1.4-.45.25-.85.5-.3.15-.65.35-.6-.65-1.15-1.25-.15-.2-.3-.3-2.3-2.4-3.65-3.65-.9-.8-1.75-1.4-1.7-1.15-3.35-1.55-1.6-.55-2.7-.55-1.65 0-3 .25-2.15.4-2.15 1.3 0 .55 1.35 2.95.7 1.25 1.9 3.35.8 1.6 1.4 3.5 0 .05.05.1.25 1.2.5 2l-2.8.7q2.5 8.9 1.4 17.95-.05.05-.05.15-.15.95-.3 1.95-.5.05-1 .15v.1l-2.2.3v.1q-2.3 11.9-8 19-.05.05-.05.1-.85 1.05-1.8 2h-.6q-.65-.05-1.1 0h-.1l-1.9.05q-1.95 4.5-5.8 8l-.6.55q-2.1 1.8-3.7 3.2-2.1 1.8-3.45 3-2.8 2.5-4.6 4.95l-.05.05q-.7.9-1.25 1.8l-6-.3q-1.25 1.35-2.45 2.8-6 7.15-10.5 15.6-.05.05-.05.1-.5.85-.9 1.7 52.5 24.25 88.55 12.7t37-12.3l-.7-1.8q-6.45-15.7-12.2-22.95-.35-.5-.65-.95-.1 0-.2.05-1.9.35-3.8.65-.55-.75-1.2-1.7l-.1-.1q-1.9-2.7-5.05-6.95-2.75-3.7-4.2-7.15-1.3-3.05-1.65-5.85v-4.05l-.9.3q-1.1.35-2.15.65-.05-.95-.1-1.85-.05-.15-.05-.25-.4-5.4-1.55-10.8z"
                    id="AccHat167__Symbol_555_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M285.3 10.7q.1-.3.25-.6.95-2.65.95-5.95 0-.9-.4-4.15.05.9.05 1.9.05.45.05 1.05.1 2.5-2.7 6.3-.05.05-.7 1-.15.2-.25.35-1.9 2.05-5.5 4-3.25 1.8-7.9 3.5-.05 0-.05.05-13.5 4.65-28.7 7.75-3.1.6-6.25 1.2-4.75.85-9.65 1.6-.25 0-.45.05-.15 0-.25.05-13.9 1.95-29.1 2.7-24.5 1.05-42.05 1.2h-4.3q-1.15-.05-2.2-.05-.7 0-1.4.05h-10.9q-17.55-.15-42.05-1.2-15.2-.75-29.1-2.7-.1-.05-.25-.05-.2-.05-.45-.05-4.9-.75-9.65-1.6-3.15-.6-6.25-1.2-15.2-3.1-28.7-7.75 0-.05-.05-.05-4.65-1.7-7.9-3.5-3.6-1.95-5.5-4-.1-.15-.25-.35-.65-.95-.7-1Q.2 5.45.3 2.95q0-.6.05-1.05Q.35.9.4 0 0 3.25 0 4.15q0 3.3.95 5.95.15.3.25.6 3.75 6.65 14.05 10.6l5.8 1.85.6.2.4.1q14.25 4.5 30.3 7.4 4.95.9 10.1 1.65 7 1 14.35 1.7 69 5.55 135.35-.25 6.05-.6 11.9-1.45 5.15-.75 10.1-1.65 16.05-2.9 30.3-7.4l.4-.1.6-.2 5.8-1.85q10.3-3.95 14.05-10.6z"
                    id="AccHat167__Symbol_557_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M146 .3q-.15-.2-.3-.3l-.6.3q-3.4 1.65-8 3.15-.1 0-.15.05l-.6.2v-.05l-2.55.65q.25 1.2.5 2l2.95-.75v.05l.6-.2q.05-.05.15-.05 5.45-1.75 9.15-3.8Q146.55.9 146 .3m14.5 17.25q-.2-.85-.45-1.65-9.8 5.65-25.2 8.8l-2 .4q-.15.95-.3 1.95.15-.05.3-.05l2-.4q15.75-3.2 25.65-9.05m63.7 119.65q4.9-.75 9.65-1.6 3.15-.6 6.25-1.2 15.2-3.1 28.7-7.75 0-.05.05-.05 4.65-1.7 7.9-3.5 3.6-1.95 5.5-4 .1-.15.25-.35.65-.95.7-1 2.8-3.8 2.7-6.3 0-.6-.05-1.05 0-1-.05-1.9-.1-1.85-.65-3.5-.15-.5-.35-1-.2-.5-.4-.95-7.05-13-58.55-4.2-7.8 1.55-12.8 1.95-55.35 9.55-79.7 14.9-19.6-5.5-65.85-15.55-5.35-.8-12.7-2.4Q7.05 90.75.75 105q-.55 1.65-.65 3.5-.05.9-.05 1.9-.05.45-.05 1.05-.1 2.5 2.7 6.3.05.05.7 1 .15.2.25.35 1.9 2.05 5.5 4 3.25 1.8 7.9 3.5.05 0 .05.05 13.5 4.65 28.7 7.75 3.1.6 6.25 1.2 4.75.85 9.65 1.6.25 0 .45.05.15 0 .25.05 13.9 1.95 29.1 2.7 24.5 1.05 42.05 1.2H144.45q.7-.05 1.4-.05 1.05 0 2.2.05h4.3q17.55-.15 42.05-1.2 15.2-.75 29.1-2.7.1-.05.25-.05.2-.05.45-.05M77.95 116.7v.2h-.2l.2-.2M183.25 64q-4.35.75-8.85 1.35L113.1 69l-13.45-.35q-.95-.05-1.95-.1h-.05l-.05.05q-.7.9-1.25 1.8.7 0 1.35.05 48.15 2.05 86.75-4.75-.55-.75-1.2-1.7m17.9 24.95h-.35q-3.95.45-7.9.8l-50.35 2.45-56.85-2.8-8.3-.9q-.05.05-.05.1-.5.85-.9 1.7 52.5 24.25 88.55 12.7t37-12.3l-.7-1.8q-.1 0-.15.05m-31.95-48.1q-.05-.95-.1-1.85-19.15 5.65-44.55 7.45-.55.1-1.3.15-.85.1-1.95.2-.85 1.05-1.8 2h.2v-.05h.15q.6-.05 1.15-.05 1.3-.1 2.25-.2.75-.05 1.3-.15 25.5-1.8 44.65-7.5z"
                    id="AccHat167__Symbol_556_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M139.7.95q-.6.1-1.2.15-59 6.75-124.35-.55-2.5-.3-4.9-.55l-2.3 6.1q-.05-.05-.1-.05L0 24.15q.05 0 .15.05 1.5.15 3 .4 81.4 10.65 152.05-.35L147.1.05l-7.4.9z"
                    id="AccHat167__Symbol_554_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#AccHat167__Symbol_555_0_Layer0_0_FILL"
                transform="translate(52.45 -26.85)"
                id="AccHat167__col1n"
            />
            <use
                xlinkHref="#AccHat167__Symbol_557_0_Layer0_0_FILL"
                transform="translate(52.45 88.8)"
                id="AccHat167__col1l"
            />
            <use
                xlinkHref="#AccHat167__Symbol_556_0_Layer0_0_FILL"
                transform="translate(52.75 -19.7)"
                id="AccHat167__col1d"
            />
            <use
                xlinkHref="#AccHat167__Symbol_554_0_Layer0_0_FILL"
                transform="translate(115.05 70.05)"
                id="AccHat167__col2n"
            />
        </g></g>
    )
}
export default AccHat167
