import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD",
    "col2d": "#3E576D",
    "col2n": "#6486A6"
}
function FacEar014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M85.25 10.35q.55-6.6-3.3-4.65-.05 0-.1.05Q71.35 9.7 56.2 5l-2.3-.9Q40.7-1.45 22.3.5q-.85.05-1.75.15-6.75.6-13.8 2.05-2.5.45-4.4 1.25-.85.3-2.2.8H.2l63.4 36.3.05.05q1.05 1.15 2.45 1.15.85 0 2.1-1 13.1-10.45 17.05-30.9m224.5-5.45l-.05-.05q-.25-.1-.15-.1-1.35-.5-2.2-.8-1.9-.8-4.4-1.25-7.05-1.45-13.8-2.05-.9-.1-1.75-.15-18.4-1.95-31.6 3.6l-2.3.9q-15.15 4.7-25.65.75-.05-.05-.1-.05-3.85-1.95-3.3 4.65 3.95 20.45 17.05 30.9 1.25 1 2.1 1 1.4 0 2.45-1.15l.05-.05v.05l63.65-36.2z"
                    id="FacEar014__Symbol_32_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M66.45 20.35q-1.5-3.05-2.6-4.3-1.1-1.3-1.5-1.7-.8-.7-2.25-1.7-1.5-1.05-3.9-2.4l-.95-.6Q35.55-3.597 9.9.25q-.094.139-.2.25Q-.247 15.324.05 28.6l-.05.15Q0 38.3 6 47.3q.6.85 1.25 2.2 1.9 3.8 2.45 5.05.85 2.25 1.3 5.2.25 1.75.55 5.25.05.25.05.5.65 14.35 13.25 18.4 3.45 1.15 8.1 1.1 2.15-.05 3.75.35 4.35 1.2 7.45 4.8 0 .05.05.1.05.1 1.05 1.45 1.65 1.85 2.85 3 2.1 2 4.35 3.5 6.7 4.25 13.65 2.95 4.2-.7 8.45-2.95.6-.35 1.9-1.15-.1-.25-.15-.3Q66.95 71.9 71 51.5q.3-1.4.7-2.9.566-2.22.95-4.15.9-4.416.9-7.4v-.65-.2q-.35-.35-.65-.8-.8-1.25-2.25-5.05-2.15-5.8-4.2-10m263.15 8.4l-.05-.15q.3-13.4-9.85-28.35-23.81-4.248-45.35 9.4l-.95.6q-2.4 1.35-3.9 2.4-1.45 1-2.25 1.7-.4.4-1.5 1.7-1.1 1.25-2.6 4.3-2.05 4.2-4.2 10-1.45 3.8-2.25 5.05-.3.45-.65.8V37.05q0 4.3 1.85 11.55.4 1.5.7 2.9 4.05 20.4-5.3 45.25-.05.05-.15.3 1.3.8 1.9 1.15 4.25 2.25 8.45 2.95 6.95 1.3 13.65-2.95 2.25-1.5 4.35-3.5 1.2-1.15 2.85-3 1-1.35 1.05-1.45.05-.05.05-.1 3.1-3.6 7.45-4.8 1.6-.4 3.75-.35 4.65.05 8.1-1.1 12.6-4.05 13.25-18.4 0-.25.05-.5.3-3.5.55-5.25.45-2.95 1.3-5.2.55-1.25 2.45-5.05.65-1.35 1.25-2.2 6-9 6-18.55z"
                    id="FacEar014__Symbol_23_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M46.45 11l-.95-.6Q25.8-2.85.15 1q-.1.15-.15.3 21.9 1.55 33.7 7.4 11.8 5.85 13.5 7.9 1.7 2 1.3 2.3-23.45 2.85-32.3 19 1.7-2.1 2.85-3.3 2.3-2.35 5.5-4.3 6.1-3.7 9.7-5 4.15-1.5 9.05-1.5 8.15 0 8.15 2.15 0 .55-1.95 2.35-2.7 2.55-4.25 4.6-6.2 8-6.2 20.15 0 .25.25 7.65h.15q.15-1.85.7-4.7l.6-4.8q.55-4.4 2.85-9.1 2.3-4.75 3.5-6.25 1.15-1.5 3.85-3.5 2.75-2 4.1-1 2.35-.05 5.3 7.05 1.1 2.7 2.55 6.8.9-4.4.9-7.4v-.65-.2q-.35-.35-.65-.8-.8-1.25-2.25-5.05-2.15-5.8-4.2-10-1.5-3.05-2.6-4.3-1.1-1.3-1.5-1.7-.8-.7-2.25-1.7-1.5-1.05-3.9-2.4M291 34.6q1.15 1.2 2.85 3.3-8.85-16.15-32.3-19-.15.65-.1 0h.1q-.4-.3 1.3-2.3 1.7-2.05 13.5-7.9 11.8-5.85 33.7-7.4-.05-.15-.15-.3-25.65-3.85-45.35 9.4l-.95.6q-2.4 1.35-3.9 2.4-1.45 1-2.25 1.7-.4.4-1.5 1.7-1.1 1.25-2.6 4.3-2.05 4.2-4.2 10-1.45 3.8-2.25 5.05-.3.45-.65.8V37.8q0 3 .9 7.4 1.45-4.1 2.55-6.8 2.95-7.1 5.3-7.05 1.35-1 4.1 1 2.7 2 3.85 3.5 1.2 1.5 3.5 6.25 2.3 4.7 2.85 9.1l.6 4.8q.55 2.85.7 4.7h.15q.25-7.4.25-7.65 0-12.15-6.2-20.15-1.55-2.05-4.25-4.6-1.95-1.8-1.95-2.35 0-2.15 8.15-2.15 4.9 0 9.05 1.5 3.6 1.3 9.7 5 3.2 1.95 5.5 4.3z"
                    id="FacEar014__Symbol_33_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacEar014__Symbol_32_0_Layer0_0_FILL"
                transform="translate(37.65 89.65)"
                id="FacEar014__col1n"
            />
            <use
                xlinkHref="#FacEar014__Symbol_23_0_Layer0_0_FILL"
                transform="translate(27.7 94.3)"
                id="FacEar014__col2n"
            />
            <use
                xlinkHref="#FacEar014__Symbol_33_0_Layer0_0_FILL"
                transform="translate(37.45 93.55)"
                id="FacEar014__col2d"
            />
        </g></g>
    )
}
export default FacEar014
