import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#D24400",
    "col1n": "#9A3200",
    "col2n": "#FFFF00"
}
function ObjFoo064({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#EEB36B"
                    d="M108.75 262.5q-.35-.05-.75 0-.25 0-.45.05l-.4.1q-.1 0-.25.05-1.65 2.6-9.4 4.4-.3.05-.65.1-.15.05-.4.05-.35.1-.7.25-.65.3-1.15.7-.1.1-.2.15-.25.2-.45.45-1.9 2.1-2.3 4.45-.25 1.45.1 3 .1.65.4 1.35 1.5 5.1 7.8 11.05l1.9 1.65q.2.2.5.45 1.568 1.337 3.15 2.6.956-.73 2.05-1.4 2.25-1.1 4.85-1.05.05.05.15.05.2 0 .4-.05 5.25.75 9.05 4.75.5.5 1 1.15l.35.4q2 2.95 2.15 5.35.05 1-.2 1.9-.05.1-.05.25l-.05.05q-.119.32-.25.6 3.67 1.754 7.5 3.2l1.4.5q5.45 1.85 9.45 2.1.45.05.9.05 2.45.2 4.6-.1 1.1-.15 2.15-.45.15 0 .3-.05 1.5-.45 2.9-1.1.6-.4 1.25-.8 1.15-1.05 2.1-2.15 2.7-3.15 4.1-7.1 1.75-6.05-2.8-8.55-29.6-13-45.3-25.7-3-2.4-4.75-2.7z"
                    id="ObjFoo064__Layer9_0_FILL"
                />
                <path
                    fill="#FCC98B"
                    d="M107.5 262.55q-.1 0-.2.05-1.65 2.6-9.45 4.45-.3 0-.6.05l-.4.1q-.4.1-.75.25-.65.25-1.15.65-.1.1-.2.15h.15q.05-.05.15-.05 1.75-.3 3.4.55l.1.1q24.05 20.55 53.3 30.7l.1.1q1.55.95 2.15 2.65.05.15.1.35.1.35.15.75.05.15.05.35-.45 3.65-3 6.85 1.7-.45 3.2-1.2.6-.4 1.25-.8 1.15-1.05 2.1-2.15 2.7-3.15 4.05-7.1 1.8-6.05-2.8-8.55-29.6-13-45.35-25.7-3-2.4-4.7-2.7-.4-.05-.75 0-.25 0-.5.05l-.4.1m52.25 36.2q.15-.25.25-.5-.45 4.1-4.25 8.4.5-1.15.7-2 .3-.95.1-2.35-.05-.4-.15-.75l-.1-.4q.1 0 .25-.05h.1q2.1-.55 3.1-2.35m-4.95 8.95l.4-.5v.05q-.2.25-.4.45z"
                    id="ObjFoo064__Layer9_1_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M59.65 40.75h.1q2.1-.55 3.1-2.35.15-.25.25-.5.75-1.6.25-3.4-.05-.15-.1-.25-.5-1.3-1.4-2-1.3-.9-4.05-2.1Q32.4 19.5 11.05 2.8q-.35-.3-.65-.55Q8.25.4 5.75.05q-.1 0-.15-.05h-.65Q4.4 0 3.8.15q-1.1.3-1.9.95-.6.45-1 1.1l-.2.3q-.5.85-.65 1.8-.15 1 .15 2.05.1.2.15.4.3-.05.6-.05 2.65.3 4.5 1.95.75.65 1.5 1.25 22.35 17.5 48.9 27.7 2.4.75 3.35 2.8l.2.4q.1 0 .25-.05z"
                    id="ObjFoo064__Symbol_148_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M56.2 31.95q-.15-.9-1.55-1.65Q28.15 20.6 5.4 2.5 4.1 1.5 2.8.4 1.65-.2.9.05-.1.5 0 1.6q.1.75 1.2 1.45 1.3 1.15 2.65 2.2 22.5 18.2 49.55 27.9 1.2.65 1.9.35.95-.45.9-1.55z"
                    id="ObjFoo064__Symbol_149_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M44.8 23.15v-.05q-1.4-2.5-4.25-2.55-.75.1-1.1.3-.95.45-1.3.55-1.1.2-1.95-.3-.8-.45-1.25-1.2l-.05-.05-.05-.1q-.15-.3-.35-.75-.55-1.2-.65-1.4-.35-.8-.55-1.2-.8-1.2-1.95-1.8-.05 0-.05-.05h-.1q-.05-.05-.15-.1-1.05-.6-2.35-.7-.5 0-1.3.15-.25-.05-1.55.15H25l-.1.05h-.05q-.7-.05-1.35-.35-.6-.4-.95-.95-.05-.05-.05-.1l-.05-.1q-.15-.3-.35-.75-.5-1.15-.65-1.4-.3-.75-.55-1.2-.75-1.1-1.8-1.7-.05-.05-.15-.1h-.05l-.05-.05q-1.1-.7-2.55-.8-.5 0-1.3.15-.25 0-1.55.15-.45.05-.8.05h-.2q-.9 0-1.65-.5-.9-.5-1.25-1.55-.1-.35-.15-1.4-.05-.4-.35-1.1Q7.6-.05 4.7.05V0q-.9.05-1.8.2-.5.1-1.5.7-.6.3-1 .6-.1.1-.2.3-.25.5-.2.95 0 .9 1.05 1.5.6.35 1.4.2.35-.05 1.05-.2Q4.65 4 5.15 4.3q.35.25.55.4.1.05.15.15.5.85.9 2.25.3 1.1.9 1.9 1.2 2.05 3.65 2.1.65.05 1-.05.5-.05.95-.15.05 0 .05-.05h.35q.3-.1.5-.1h.3q1.3-.1 2.1.4.1 0 .1.05.05 0 .1.05.05 0 .1.05.05 0 .1.05.75.5 1.3 1.55.05.15.15.3.1.2.15.5.1.1.15.25v.1q.15.45.35.9.1.4.5.9.7 1.15 1.75 1.7 1 .65 2.4.7.65.05 1-.05.5-.05.95-.15.05 0 .1-.05h.3q.25-.1.45-.15.2.05.35 0 1.2-.05 2.05.35l.05.05q.05.05.1.05l.1.1q.05 0 .1.05.8.45 1.4 1.6.05.15.15.3.1.2.15.45l.15.3v.1l.3.9q.15.4.5.9 1.25 2.05 3.65 2.1 1 .1 2.15-.2 1.35-.35 2.35-.3.1 0 .2.05.25.1.65.25.5.3.85 1.45.2.65.35 1 .3.75.85 1.05 1.1.65 1.85.2.4-.2.7-.7l.15-.3q.1-.45.1-1.15-.05-1.15-.2-1.65-.35-.9-.75-1.65z"
                    id="ObjFoo064__Symbol_147_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjFoo064__Layer9_0_FILL" />
            <use xlinkHref="#ObjFoo064__Layer9_1_FILL" />
            <use
                xlinkHref="#ObjFoo064__Symbol_148_0_Layer0_0_FILL"
                transform="translate(96.9 260.35)"
                id="ObjFoo064__col1n"
            />
            <use
                xlinkHref="#ObjFoo064__Symbol_149_0_Layer0_0_FILL"
                transform="translate(101.55 262.35)"
                id="ObjFoo064__col1l"
            />
            <use
                xlinkHref="#ObjFoo064__Symbol_147_0_Layer0_0_FILL"
                transform="translate(105.6 266.25)"
                id="ObjFoo064__col2n"
            />
        </g></g>
    )
}
export default ObjFoo064
