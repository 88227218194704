import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#FFE000",
    "col1n": "#FFFF00",
    "col2d": "#C40000",
    "col2n": "#FF0000"
}
function ObjMor102({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M82.5 15.95q.4-1.6.25-3.5-.25-1.7-1.25-3.8-.65-1.4-2.1-2.65-.25-.25-.6-.3-.4-.1-1.8-.2-1.45-.1-1.9-1.1-.45-1.05-.75-1.15-.3-.1-.55-.1-.5-.15-1.05-.2-.1-.05-.2-.05-.75 0-1.4.05-1.2.05-2.2.35-.5.2-1 .35-.05.05-1.65 2.5-1.45 2.15-2.15 3.35 0 8.2 1.15 9.1-.45 0-1.5-.1-1.1-.15-4.25-1.55-.25.25-1.2-.65-1-.95-1.4-1.35l-.85-.3-6.8-1.8q-3.95.05-6.15-1.7l-7.1-1.85q-5.1.25-6.25-1.7l-6.3-1.65Q18 6.3 17.1 4.2l-5.35-1.4Q6.45 2.9 5.1 1L1.4 0Q-1.188.69 1 2.85q.55.45.55 1.25Q1.5 4.85 0 5.85q.3.6.75 1.6l5.3 1.5q4.65 0 5.8 1.7l5.55 1.6q4.55-.3 5.65 1.7l6.9 2q5.05-.3 6.3 1.8l5.85 1.7q5 .1 7.9 2.3l7.7 2.3 3.15.25q7.35 1 6.35 1.85 5.7.15 9.6-2.25.3-.2.55-.45l.2-.15q.7-.5 1.4-1.1.9-3.25 3.15-5 0-.1.05-.2.2-.5.35-1.05z"
                    id="ObjMor102__Symbol_257_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M1.4 11.5q.2.2.5.6.1.15.25.4.25.3.6.85 4.05-.05 4.3-1.9-.6-.45-1.05-.8-.15-.2-.35-.35-.25.05-.45.1-.8.15-1.5 0l-2.2-.55Q.7 9.7 0 9.75q.7.85 1.4 1.75m20.55-7.7q-.15-1-.8-2.05-.8-1.3-1.8-1.6-1.25-.35-2.85.1.1.05.15.15 1.4 1.15 2.7 3.3.3.5.45.9.3.75.4 1.45.05 1-.35 1.95-.15.2-.2.45-.15.35-.3.5l-.1.1q0-.05.05-.1l.25-.55q.2-.6.2-1.1-1.95-.8-3.95-.7.15-.25.25-.45Q17 4 17.3 3.7q-1-.05-2.45.7-.5.35-.95.6l-.5.25h-.05q-1.5-.85-2.4-1.55-.15.25-.4.4Q9.4 5.6 9 7q-.1.25-.15.5-.05.2-.1.35 1.1.8 3.7.6Q11.2 8.8 9.8 12l.15.15q.35.4.4.45.25.15.5.35l.75.25.75.25q.2.05.4.05.9.1 1.75-.35.95-.3 1.65-.85.1-.05 1-.75.85-.6 1-.9.05-.05.1-.05-.05.05-.1.15-.2.2-.45.5h-.05v.05q-.2.35-1.55 1.45 0 .05-.05.05-.55.6-1.25 1h.05q-1.45.7-2.85.45-.6.25-2.35-.05-1.8-.3-1.35 2.45 5.65 0 8.65-2l.2-.15q.7-.5 1.4-1.1.9-3.25 3.15-5 0-.1.05-.2.3-1.35.35-1.95.05-.55-.15-2.45m-3.6 6.55l-.05-.05h.05v.05z"
                    id="ObjMor102__Symbol_259_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M.85 6.05l1.35.4 11-4.65L6.55 0l-4.1 1.85Q1.1 2.1.3 3.1q-.85 1 .55 2.95m6.65 1.9l5.8 1.7 11.65-4.7-6.4-1.75L7.5 7.95m17 5l13-4.65-6.25-1.7-12.4 4.65 5.65 1.7m26.25-1.1l-6.15-1.7-13.2 4.8 6.3 1.8 13.05-4.9m13.85 9.3q-.3.1-.55.25-.6.2-1.3.4-2.25.8-3.6 1.25l2.35.6q6.6 1.85 7.15 1.5.75-.2 1.4-.65.05 0 .1-.05 1.7-1.05 2.1-1.25 1.15-.8 1.6-1.15-.2-.05-.35-.15-1.5-.45-3.5-1.8-.55-.4-1.1-.85-1.55.85-4.3 1.9m-.1-10.8q-.15.95-.15 1.7-.1 1.35-.1 1.75 0 .45.1.7.05.2.15.4v.05q.9 1.6 2.25 2.65-.3-.1-.7-.2Q64.6 16.95 63 16q-1.2-.95-3.35-1.7-.6-.2-1.25-.35l-.85-.3-.8.5q-1.9 1.15-4.45 2.2-5 2-8.75 2.1l7.9 2.3q3.15-.4 6.1-1.45 1.05-.45 2.4-1 .5-.25.8-.35.25-.1.4-.15l.3-.15q.2-.1.4-.1.7-.05 1.5.1l2.2.55q.7.15 1.5 0 .2-.05.45-.1.2.15.35.35-1.6-5.5 1.55-15.8l-.1.1q-3.15 1.5-4.4 5.8-.2.7-.4 1.8M79.9 4.2q-1.65-1.25-3.45-1.75-.35-.1-.65-.2Q72.45 6 71.1 12.9q-.1.9-.35 1.8 0 .2-.05.6.05-.25.15-.5.4-1.4 1.55-2.9.25-.15.4-.4 1.05-1.1 2.3-1.95.75-.5 1.4-.85.9-.35 1.75-.65.05.05.1 0V8q.7-1.85 2.5-3-.25-.25-.6-.5-.15-.15-.35-.3m1.75 8.2q.3.75.4 1.45.05 1-.35 1.95-.15.2-.2.45-.15.35-.3.5-.2.4-.5.75-.25.5-.7 1.05-.2.2-.45.5h-.05v.1q.6 1.2.75 1.7.05.15.15.35 2-2.05 3.15-5-.6-2.1-1.3-3.2-.75-1.1-1.05-1.5.3.5.45.9m-.2 1q-.05-.4-.4-.85v-.1q-.05-.05-.1-.05-.5-.65-1.2-.85-.3-.1-.6-.05l-.4.4q-2.6 2.65-6.7 3.55-.75.2-1.45.2-.1.7-.05 1.3.05 1.45 1.05 2.85h.05q2.3.2 4.95-1.15v-.05q3.1-1.4 5-3.5.05-.45.1-.7 0-.25-.25-1z"
                    id="ObjMor102__Symbol_260_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M7.1 6.1q-.3-.1-.7-.2-1.45-.45-3.05-1.4Q2.15 3.55 0 2.8q.8 1.25 2.15 3.1.05.1.05.15.7-.05 1.5.1l2.2.55q.7.15 1.5 0 .2-.05.45-.1-.4-.25-.7-.5H7.1m5.5 5.6q1.15-.8 1.6-1.15-.2-.05-.35-.15-1.5-.45-3.5-1.8-.55-.4-1.1-.85-1.55.85-4.3 1.9.05.1.05.2.75 1 1.6 1.6.45.4 1.6.95.15.1 1.15.35l.05-.05q.1.05.1.1.2.1.4.15.3-.05.6 0 1.7-1.05 2.1-1.25m10-10.2Q21.85.4 21.55 0q.3.5.45.9.3.75.4 1.45.05 1-.35 1.95-.15.2-.2.45-.15.35-.3.5-.2.4-.5.75-.25.5-.7 1.05-.2.2-.45.5h-.05v.1q.6 1.2.75 1.7.05.15.15.35 2-2.05 3.15-5-.6-2.1-1.3-3.2m-.55 1.4q0-.25-.25-1-.05-.4-.4-.85v-.1Q21.35.9 21.3.9q-.5-.65-1.2-.85-.3-.1-.6-.05l-.4.4q-2.6 2.65-6.7 3.55-.75.2-1.45.2-.1.7-.05 1.3.05 1.45 1.05 2.85H12q2.3.2 4.95-1.15V7.1q3.1-1.4 5-3.5.05-.45.1-.7z"
                    id="ObjMor102__Symbol_258_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M219.55 232.5q.05-.6 0-1.25l-6.8-1.05h-.05l-22.1-5.75q-1.17 1.213-1.7 3.05-.571 1.877-.2 3.55l21.75 7 6.25 2.8q.5-.65.85-1.3.8-1.55 1.35-3.3.1-.45.25-.9.3-1.45.4-2.85z"
                    id="ObjMor102__Layer1_0_FILL"
                />
                <path
                    fill="#E0E0E0"
                    d="M212.05 230q-.053 2.112-.6 4.05-.55 2-1.7 3.75l.65.2.05.05q1.1-1.8 1.7-3.75.55-2 .6-4.1h-.05l-.65-.2z"
                    id="ObjMor102__Layer1_1_FILL"
                />
                <path
                    fillOpacity={0.2}
                    d="M291.9 246.6q-1.55-.85-2.35-1.55-.25.2-.45.4-1.15 1.45-1.55 2.9-.1.25-.15.45l-.1.4q-.1.7-.05 1.3.05 1.35 1.05 2.8v.05l.15.15q.4.4.45.4.2.15.5.3.25.2.7.4l.75.2h.4q.95.1 1.8-.35.9-.25 1.6-.8.1-.05 1.05-.8.8-.65 1-.9.047-.094.05-.15-.003.105-.1.25.4-.5.8-1.05.15-.25.35-.6v-.1q.3-.6.3-.65.15-.55.2-1.05.05-.45.05-.65 0-.25-.2-1.05-.15-.4-.4-.85l-.05-.05q-.05-.05-.1-.05-.45-.7-1.15-.9-.25-.1-.55-.05-1.1-.05-2.5.75-.55.3-1 .55l-.5.3m3.4.8q.052.05.1.05v.05h-.05q0-.048-.05-.1m1.5 4.25q0-.05.1-.05l-.05.05v.05l-.05-.05z"
                    id="ObjMor102__Layer1_2_FILL"
                />
            </defs>
            <use
                xlinkHref="#ObjMor102__Symbol_257_0_Layer0_0_FILL"
                transform="translate(218.15 232.5)"
                id="ObjMor102__col1n"
            />
            <use
                xlinkHref="#ObjMor102__Symbol_259_0_Layer0_0_FILL"
                transform="translate(278.55 241.3)"
                id="ObjMor102__col1d"
            />
            <use
                xlinkHref="#ObjMor102__Symbol_260_0_Layer0_0_FILL"
                transform="translate(216.7 233.5)"
                id="ObjMor102__col2n"
            />
            <use
                xlinkHref="#ObjMor102__Symbol_258_0_Layer0_0_FILL"
                transform="translate(276.35 245)"
                id="ObjMor102__col2d"
            />
            <use xlinkHref="#ObjMor102__Layer1_0_FILL" />
            <use xlinkHref="#ObjMor102__Layer1_1_FILL" />
            <use xlinkHref="#ObjMor102__Layer1_2_FILL" />
        </g></g>
    )
}
export default ObjMor102
