import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1l": "#CB9AFF",
    "col1n": "#B46BFF",
    "col1s": "#6B13C4",
    "col2d": "#1082E7",
    "col2n": "#43A6FF"
}
function LegSho017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M8.1 3.5q1-1.55 0-2.7Q4.15-.25 0 .8v8.25h8.1V3.5m20-.05q6.15 2.25 12.15 0V.8q-6-.95-12.15 0v2.65m39.85 5.6V.8q-3.6-1.55-8.1 0-1.2 1.45 0 2.7v5.55h8.1M72.75.8v8.25h8.1V3.5q1.15-1.45 0-2.7-4.05-1.3-8.1 0m27.8 0v2.65q6.15 2.25 12.15 0V.8q-6.15-1.15-12.15 0m32.05 0q-1.15 1.35 0 2.7v5.55h8.1V.8q-4.05-1.35-8.1 0z"
                    id="LegSho017__Symbol_51_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M8.1 2.85V0H0v2.85h8.1m64.65 0h8.1V0h-8.1v2.85m-12.9 0h8.1V0h-8.1v2.85m72.75 0h8.1V0h-8.1v2.85z"
                    id="LegSho017__Symbol_50_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M0 6.35h4.2V.8Q2.3-.5 0 .8v5.55M51.75.8q-2-1-4.2 0v5.55h4.2V.8m21 5.55h4.2V.8q-2.1-1.55-4.2 0v5.55m51.75 0V.8q-2.1-1.3-4.2 0v5.55h4.2z"
                    id="LegSho017__Symbol_48_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M147.05 6.45q-1.7-1.4-4.3-2.5-.05-.05-.1-.05-.3-.15-.55-.25l-.9-.35q-.35-.1-.75-.25-.15-.05-.35-.1-.15-.05-.35-.1-4.05-1.05-8.6-1.7-1-.15-2.05-.3Q122.85.1 115.8.1h-.7q-1.25-.1-2.5-.1h-.1q-3.55-.05-7.2.05-1.4.05-2.8.15-2.95.15-5.9.45-.25 0-.5.05l-.5.1q-.1-.05-.2 0-1.25.15-2.55.35-.15 0-.35.05-.6.1-1.15.2-.9.1-1.75.25-3.8.6-7.7 1.5L73.6 5.2l-8.3-2.05q-3.55-.85-7.05-1.45l-1.2-.2q-.35-.05-.65-.1-1.05-.15-2.05-.3l.05.05Q53 .9 51.6.75V.8l-.5-.1q-.25-.05-.5-.05-2.9-.3-5.8-.45h-.15Q43.3.1 41.95.05q-3.7-.1-7.25-.05h-1.3q-.6.05-1.2.05V.1h-.9q-6.6 0-12.5.7-.55.05-1.1.15-.25 0-.45.05-.3 0-.55.05-3.5.5-6.75 1.2-.7.15-1.4.35Q2.9 4.15 0 6.65q38.6 2.6 73.5 0 .05 0 .1-.05.05.05.1.05 37.8 1.75 73.45 0l-.1-.2z"
                    id="LegSho017__Symbol_47_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M41.2 2.65q.2-.35.35-.95l-1.2-.2q-.35-.05-.65-.1-1.05-.15-2.05-.3l.05.05Q36.3.9 34.9.75V.8l-.5-.1q-.25-.05-.5-.05Q31 .35 28.1.2h-.15Q26.6.1 25.25.05 21.55-.05 18 0h-1.3q-.6.05-1.2.05V.1h-.9Q8 .1 2.1.8 1.55.85 1 .95.75.95.55 1q-.3 0-.55.05v.1q.05.55.2.85.55 1.35 3.05 1.35h1.5q.3-.05.55-.05 1.6-.15 3.3-.25 3.3-.2 6.75-.2h.7q1.25-.1 2.5-.1h.1q3.55-.05 7.2.05 1.4.05 2.8.15 1.9.1 3.8.25l2.1.2q.25 0 .5.05.15.05.25.05.15.05.25.05.1-.05.2 0 .1 0 .2.05l2.45.1q.05 0 .1-.05v.05q2.05 0 2.7-1.05m73.25-1.5q-1-.15-2.05-.3Q106.15.1 99.1.1h-.7Q97.15 0 95.9 0h-.1q-3.55-.05-7.2.05-1.4.05-2.8.15-2.95.15-5.9.45-.25 0-.5.05l-.5.1q-.1-.05-.2 0-1.25.15-2.55.35-.15 0-.35.05-.6.1-1.15.2-.9.1-1.75.25.15.65.35 1 .65 1.05 2.7 1.05v-.05q.05.05.1.05l2.45-.1q.1-.05.2-.05.1-.05.2 0 .1 0 .25-.05.1 0 .25-.05.25-.05.5-.05 2.95-.3 5.9-.45 1.4-.1 2.8-.15 3.65-.1 7.2-.05h.1q1.25 0 2.5.1h.7q5.25 0 10.05.45.25 0 .55.05h1.5q2.5 0 3.05-1.35.15-.3.2-.85z"
                    id="LegSho017__Symbol_46_0_Layer0_0_FILL"
                />
                <path
                    d="M118.9 332.7q-.026.043-.05.05v2.2h147.3v-2.1q-.048-.062-.1-.15H192.6q-.052-.002-.1-.05-.048.048-.1.05h-73.5m38 1.3h-.1q.02-.024.05-.05.02.026.05.05z"
                    id="LegSho017__Layer1_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#LegSho017__Symbol_51_0_Layer0_0_FILL"
                transform="translate(122.15 334.15)"
                id="LegSho017__col1n"
            />
            <use
                xlinkHref="#LegSho017__Symbol_50_0_Layer0_0_FILL"
                transform="translate(122.15 337.65)"
                id="LegSho017__col1l"
            />
            <use
                xlinkHref="#LegSho017__Symbol_48_0_Layer0_0_FILL"
                transform="translate(130.25 334.15)"
                id="LegSho017__col1s"
            />
            <use
                xlinkHref="#LegSho017__Symbol_47_0_Layer0_0_FILL"
                transform="translate(118.9 326.05)"
                id="LegSho017__col2n"
            />
            <use
                xlinkHref="#LegSho017__Symbol_46_0_Layer0_0_FILL"
                transform="translate(135.6 326.05)"
                id="LegSho017__col2d"
            />
            <use xlinkHref="#LegSho017__Layer1_0_FILL" />
        </g></g>
    )
}
export default LegSho017
